<template>
  <div>
    <div class="bg-white">
      <div class="flex justify-between">
        <div class="px-4 py-2">
          <form action="#">
            <div class="flex">
              <div class="mr-2">
                <label for="ssn" class="block text-sm font-medium leading-5 text-gray-700">{{
                  $t("patient.ssn")
                }}</label>
                <div class="rounded-xs">
                  <input
                    v-focus
                    v-model="ssn"
                    id="ssn"
                    type="text"
                    autocomplete="off"
                    required
                    class="appearance-none block w-full px-1 py-1 border border-gray-300 rounded-xs placeholder-gray-400 focus:outline-none focus:ring-1 focus:ring-blue-600 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                  />
                </div>
              </div>
              <div class="mr-2">
                <label for="last_name" class="block text-sm font-medium leading-5 text-gray-700">{{
                  $t("patient.last_name")
                }}</label>
                <div class="rounded-xs">
                  <input
                    v-model="last_name"
                    id="last_name"
                    type="text"
                    autocomplete="off"
                    required
                    class="appearance-none block w-full px-1 py-1 border border-gray-300 rounded-xs placeholder-gray-400 focus:outline-none focus:ring-1 focus:ring-blue-600 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                  />
                </div>
              </div>
              <div class="mr-2">
                <label for="first_name" class="block text-sm font-medium leading-5 text-gray-700">{{
                  $t("patient.first_name")
                }}</label>
                <div class="rounded-xs">
                  <input
                    v-model="first_name"
                    id="first_name"
                    type="text"
                    autocomplete="off"
                    required
                    class="appearance-none block w-full px-1 py-1 border border-gray-300 rounded-xs placeholder-gray-400 focus:outline-none focus:ring-1 focus:ring-blue-600 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                  />
                </div>
              </div>
              <div class="mr-2">
                <label for="id" class="block text-sm font-medium leading-5 text-gray-700">{{ $t("patient.id") }}</label>
                <div class="rounded-xs">
                  <input
                    v-model="id"
                    id="id"
                    type="text"
                    autocomplete="off"
                    required
                    class="appearance-none block w-full px-1 py-1 border border-gray-300 rounded-xs placeholder-gray-400 focus:outline-none focus:ring-1 focus:ring-blue-600 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                  />
                </div>
              </div>
              <div class="flex items-end">
                <cm-button @click.prevent="search" type="submit">
                  {{ $t("button.search") }}
                </cm-button>
              </div>
            </div>
          </form>
        </div>
        <div>
          <div
            v-if="patientlist && patientlist.total > 0"
            class="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-3"
          >
            <div class="flex-1 flex justify-between sm:hidden">
              <a
                href="#"
                class="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:ring-1 focus:ring-blue-600 active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150"
                >{{ $t("button.previous") }}</a
              >
              <a
                href="#"
                class="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:ring-1 focus:ring-blue-600 active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150"
                >{{ $t("button.next") }}</a
              >
            </div>
            <div class="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
              <div class="mr-4">
                <p class="text-sm leading-5 text-gray-700">
                  {{ $t("pager.label.showing") }}
                  <span class="font-medium">{{ offset + 1 }}</span>
                  {{ $t("pager.label.to") }}
                  <span class="font-medium">{{ pagerToNumber }}</span>
                  {{ $t("pager.label.of") }}
                  <span class="font-medium">{{ patientlist.total }}</span>
                  {{ $t("pager.label.results") }}
                </p>
              </div>
              <div v-show="patientlist.total > limit">
                <span class="relative z-0 inline-flex shadow-sm">
                  <button
                    type="button"
                    :disabled="offset === 0"
                    class="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm leading-5 font-medium text-gray-500 hover:text-gray-400 focus:z-1 focus:outline-none focus:ring-1 focus:ring-blue-600 ring-inset active:bg-gray-100 active:text-gray-500 transition ease-in-out duration-150"
                    @click="prevPage"
                    :class="{ 'opacity-25': offset === 0 }"
                  >
                    <svg class="h-5 w-5" fill="currentColor" viewBox="0 0 20 20">
                      <path
                        fill-rule="evenodd"
                        d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </button>

                  <button
                    type="button"
                    v-for="page in pages"
                    :key="page"
                    :class="{ 'font-semibold text-gray-900': page == currentPage }"
                    @click="gotopage(page)"
                    class="-ml-px relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm leading-5 font-medium text-gray-700 hover:text-gray-500 focus:z-1 focus:outline-none focus:ring-1 focus:ring-blue-600 ring-inset active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150"
                  >
                    {{ page }}
                  </button>

                  <button
                    type="button"
                    :disabled="currentPage === pages"
                    @click="nextPage"
                    class="-ml-px relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm leading-5 font-medium text-gray-500 hover:text-gray-400 focus:z-1 focus:outline-none focus:ring-1 focus:ring-blue-600 ring-inset active:bg-gray-100 active:text-gray-500 transition ease-in-out duration-150"
                    :class="{ 'opacity-25': currentPage === pages }"
                  >
                    <svg class="h-5 w-5" fill="currentColor" viewBox="0 0 20 20">
                      <path
                        fill-rule="evenodd"
                        d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </button>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-4" v-if="patientlistWithDiagnoses">
        <patient-list-result :headers="showHeaders" :list="patientlistWithDiagnoses" @updateSort="updateSort" />
      </div>
      <div v-else>
        <div class="h-full bg-gray-100 flex justify-center items-center">
          <div></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { mapFields } from "vuex-map-fields";
import PatientListResult from "@/components/user/patient/PatientListResult";

export default {
  name: "PatientList",
  data() {
    return {
      showHeaders: ["id", "ssn", "first_name", "last_name", "diagnosistext", "compos3_id"],
      loaded: false,
      offset: 0,
      limit: 15,
      sort: {
        col: "id",
        asc: false,
      },
      maxButtons: 5,
    };
  },
  components: {
    PatientListResult,
  },
  watch: {
    patientlistWithDiagnoses() {
      //console.log(a)
    },
  },
  computed: {
    ...mapState("userExtra", {
      patientlist: state => state.patientlist,
    }),
    ...mapFields("userExtra", ["ssn", "id", "first_name", "last_name", "patientlist"]),
    patientlistWithDiagnoses() {
      if (!this.patientlist?.data) {
        return [];
      }
      let diagnosisdata = JSON.parse(JSON.stringify(this.patientlist.data));
      return diagnosisdata.map(d => {
        d.diagnosistext = d.diagnoses.map(d => d.diagnosis.name);
        return d;
      });
    },
    query() {
      let sortObj = {
        sort_column: this.sort.col,
        sort_order: this.sort.asc ? "asc" : "desc",
      };

      let search = {
        ssn: this.ssn,
        id: this.id,
        first_name: this.first_name,
        last_name: this.last_name,
      };

      Object.keys(search).forEach(key => {
        !search[key] && delete search[key];
      });

      let paginationObj = {
        offset: this.offset,
        limit: this.limit,
      };

      let searchString = new URLSearchParams(search).toString();
      let sortString = new URLSearchParams(sortObj).toString();
      let paginationString = new URLSearchParams(paginationObj).toString();

      return [searchString, sortString, paginationString].join("&");
    },
    currentPage() {
      return Math.floor(this.offset / this.limit) + 1;
    },
    pages() {
      if (!this.patientlist?.total) {
        return null;
      }

      return Math.ceil(this.patientlist.total / this.limit);
    },
    pagerToNumber() {
      if (!this.patientlist?.total) {
        return null;
      }

      return this.currentPage === this.pages ? this.patientlist.total : this.offset + this.limit;
    },
  },
  methods: {
    search() {
      this.loaded = false;
      this.$store
        .dispatch("user/loadPatientList", {
          query: this.query,
        })
        .then(() => {
          this.loaded = true;
        });
    },
    prevPage() {
      this.offset = this.offset >= this.limit ? this.offset - this.limit : 0;
      this.search();
    },
    nextPage() {
      this.offset = this.offset <= this.patientlist.total ? this.offset + this.limit : this.total - this.limit;

      this.search();
    },
    gotopage(page) {
      this.offset = page > 1 ? (page - 1) * this.limit : 0;

      this.search();
    },
    updateSort(header) {
      this.sort.col = header;
      this.sort.asc = !this.sort.asc;
      this.search();
    },
  },
};
</script>
