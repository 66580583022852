<template>
  <div class="flex flex-col">
    <div class="overflow-x-auto">
      <div class="align-middle inline-block min-w-full overflow-hidden sm:rounded-lg border-b border-gray-200">
        <table class="min-w-full" v-if="list.length">
          <thead>
            <tr>
              <th
                v-for="(header, key) in headers"
                :key="key"
                class="px-4 py-3 border-b border-gray-200 bg-white text-left text-sm leading-4 font-medium text-gray-800 tracking-wider truncate"
                @click="updateSort(header)"
              >
                {{ $t(`patient.${header}`) }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(item, key) in list"
              :key="key"
              class="group odd:bg-gray-100 first:border-t border-b border-gray-200"
              @click="activatePatient(item.id)"
            >
              <td
                v-for="(header, key) in headers"
                :key="key"
                class="px-4 py-2 align-top group-hover:bg-blue-100 group-hover:cursor-pointer"
                :tabindex="header === 'id' ? key : null"
              >
                <div
                  class="text-sm leading-5 text-gray-900"
                  v-if="Array.isArray(item[header])"
                  v-html="item[header].join('<br>')"
                ></div>
                <div v-else class="text-sm leading-5 text-gray-900">{{ item[header] }}</div>
              </td>
              <td class="px-4 py-2 align-top group-hover:bg-blue-100 group-hover:cursor-pointer">
                <div></div>
              </td>
            </tr>
          </tbody>
        </table>

        <div v-else class="p-4">
          <h3 class="font-bold">{{ $t('list.noresult') }}</h3>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PatientListResult",
  props: ["headers", "list"],
  data: () => ({
    clicked: false,
  }),
  methods: {
    activatePatient(id) {
      if (this.clicked) return;
      this.clicked = true;
      this.$store.dispatch("user/activatePatient", id).then(() => {
        this.clicked = false;
      });
    },
    updateSort(header) {
      this.$emit("updateSort", header);
    },
  },
};
</script>
